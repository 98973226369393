<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
    >
      <b-carousel-slide :img-src="require('@/assets/images/banner/sabaideeV3.jpg')" />
      <div>
        <svg
          class="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shape-rendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g class="parallax">
            <use
              xlink:href="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(135, 253, 237, 0.3)"
            />
            <use
              xlink:href="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(135,253,237,0.5)"
            />
            <use
              xlink:href="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(135,253,237,0.3)"
            />
            <use
              xlink:href="#gentle-wave"
              x="48"
              y="7"
              fill="#01B295"
            />
          </g>
        </svg>
      </div>
    </b-carousel>
  </div>
</template>

<script>
import {
  BCarousel, BCarouselSlide,
} from 'bootstrap-vue'

export default {
  components: {
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    }
  },
  methods: {
  },
}
</script>

<style>

</style>
