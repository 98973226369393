<template>
  <div>
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <banner-slide />
      <div class="container h-100">
        <!-- <b-row>
          <b-col md="12">
            <article class="top-search lottery-search pb-2 pt-2">
              <div class="text-center">
                <h1><i class="fa-solid fa-magnifying-glass" /> ค้นหาสลากฯ</h1>
              </div>
              <div class="container-sm">
                <h5>งวดวันที่ {{ round.roundth }}</h5>
                <div class="d-flex justify-content-center">
                  <otp-input
                    v-if="renderComponent"
                    v-model="input_numlot"
                    :digits="6"
                  >
                    <template #errorMessage>
                      There is an error
                    </template>
                  </otp-input>

                </div>

                <b-row class="mt-3">
                  <b-col cols="4">
                    <b-button
                      block
                      size="lg"
                      variant="outline-primary"
                      @click="cleartext()"
                    >
                      เคลียร์
                    </b-button>
                  </b-col>
                  <b-col cols="8">
                    <b-button
                      block
                      size="lg"
                      class="btn-search"
                      @click="Search()"
                    >
                      ค้นหา
                    </b-button>
                  </b-col>
                </b-row>

              </div>
            </article>
          </b-col>
        </b-row> -->

        <!-- <b-card class="mt-4">
        <div class="d-flex align-items-center justify-content-center">
          <span>เลือกชุดหวย :</span>
          <b-button class="ml-1 btn-clear">
            1 ใบ
          </b-button>
          <b-button class="ml-1 btn-clear">
            2 ใบ
          </b-button>
          <b-button class="ml-1 btn-clear">
            3 ใบ
          </b-button>
          <b-button class="ml-1 btn-clear">
            4 ใบ
          </b-button>
          <b-button class="ml-1 btn-clear">
            5 ใบ
          </b-button>
        </div>
      </b-card> -->
        <b-card class="mt-2">
          <!-- icon search -->
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h3 class="font-weight-bolder">
                <img
                  src="img/reward.svg"
                  alt=""
                  height="50"
                >
                ผลสลากสบายดี
              </h3>
              <!-- <p class="lottery-subtext">
                ผลสลากสบายดี <span class="lottery-content text-danger">งวดที่ {{ ResultLast ? ResultLast.roundth: '-' }}</span>
              </p> -->
            </div>

            <a href="https://www.facebook.com/100064468341469/videos/1535434360235581/">
              <img
                src="/img/live.png"
                alt="Live"
                height="50"
                class="cursor-pointer tv-live"
              >
            </a>
          </div>
          <!-- <h3 class="font-weight-bolder">
            <img
              src="img/reward.svg"
              alt=""
              height="50"
            >
            ผลสลากสบายดี
          </h3>-->
          <p class="lottery-subtext">
            ผลสลากสบายดี <span class="lottery-content text-danger">งวดที่ {{ ResultLast ? ResultLast.roundth: '-' }}</span>
          </p>

          <div class="box-lotto special">

            <div class="box-title">
              <img
                :src="require('@/assets/images/lottery/loas.png')"
                class="img-title"
              ><strong>หวยลาวพัฒนา {{ ResultLast ? ResultLast.roundth : 'ยังไม่มีผลรางวัลล่าสุด' }}</strong>
            </div>

            <b-row class="match-height text-center">
              <b-col
                lg="12"
                md="12"
                cols="12"
                class="border-right border-bottom p-1"
              >
                <div class="inner-col">
                  <div class="h4 mb-0">
                    เลข 6 ตัว
                  </div>
                  <small>2,400,000 บาท</small>
                  <div class="h2 mt-1 text-danger">
                    {{ ResultLast ? ResultLast.num6 : '-' }}
                  </div>

                </div>
              </b-col>
              <b-col
                lg="2"
                md="4"
                cols="6"
                class="border-right border-bottom p-1"
              >
                <div class="inner-col">
                  <div class="h4 mb-0">
                    เลข 5 ตัว
                  </div>
                  <small>80,000 บาท</small>
                  <div class="h2 mt-1">
                    {{ ResultLast ? ResultLast.num5 : '-' }}
                  </div>

                </div>
              </b-col>
              <b-col
                lg="2"
                md="4"
                cols="6"
                class="border-right border-bottom p-1"
              >
                <div class="inner-col">
                  <div class="h4 mb-0">
                    เลข 4 ตัว
                  </div>
                  <small>24,000 บาท</small>
                  <div class="h2 mt-1">
                    {{ ResultLast ? ResultLast.num4 : '-' }}
                  </div>

                </div>
              </b-col>
              <b-col
                lg="2"
                md="4"
                cols="6"
                class="border-right border-bottom p-1"
              >
                <div class="inner-col">
                  <div class="h4 mb-0">
                    เลขหน้า 3 ตัว
                  </div>
                  <small>1,400 บาท</small>
                  <div class="h2 mt-1">
                    {{ ResultLast ? ResultLast.num3_front : '-' }}
                  </div>

                </div>
              </b-col>
              <b-col
                lg="2"
                md="4"
                cols="6"
                class="border-right border-bottom p-1"
              >
                <div class="inner-col">
                  <div class="h4 mb-0">
                    เลขท้าย 3 ตัว
                  </div>
                  <small>1,400 บาท</small>
                  <div class="h2 mt-1">
                    {{ ResultLast ? ResultLast.num3_back : '-' }}
                  </div>

                </div>
              </b-col>
              <b-col
                lg="2"
                md="4"
                cols="6"
                class="border-right border-bottom p-1"
              >
                <div class="inner-col">
                  <div class="h4 mb-0">
                    เลข 2 ตัว
                  </div>
                  <small>800 บาท</small>
                  <div class="h2 mt-1">
                    {{ ResultLast ? ResultLast.num2 : '-' }}
                  </div>

                </div>
              </b-col>
              <b-col
                lg="2"
                md="4"
                cols="6"
                class="border-right border-bottom p-1"
              >
                <div class="inner-col">
                  <div class="h4 mb-0">
                    เลข 1 ตัว
                  </div>
                  <small>80 บาท</small>
                  <div class="h2 mt-1">
                    {{ ResultLast ? ResultLast.num1 : '-' }}
                  </div>

                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>

        <b-card
          v-if="Result.length > 0"
          class="mt-2"
        >
          <!-- icon search -->
          <h3 class="font-weight-bolder">
            <img
              src="img/check.svg"
              alt=""
              height="50"
            >
            ตรวจสลากสบายดี ย้อนหลัง
          </h3>
          <p class="lottery-subtext">
            ตรวจสอบตัวเลขสลากกินแบ่งและงวดที่ออกของท่านทุกครั้ง เพื่อความถูกต้อง
          </p>
          <div>
            <div
              v-for="item in Result"
              :key="item"
              class="box-lotto special"
            >

              <div class="box-title2">
                <img
                  :src="require('@/assets/images/lottery/loas.png')"
                  class="img-title"
                ><strong>หวยลาวพัฒนา {{ item.roundth }}</strong>
              </div>

              <b-row class="match-height text-center">
                <b-col
                  lg="12"
                  md="12"
                  cols="12"
                  class="border-right border-bottom p-1"
                >
                  <div class="inner-col">
                    <div class="h4 mb-0">
                      เลข 6 ตัว
                    </div>
                    <small>2,400,000 บาท</small>
                    <div class="h2 mt-1 text-danger">
                      {{ item.num6 }}
                    </div>

                  </div>
                </b-col>
                <b-col
                  lg="2"
                  md="4"
                  cols="6"
                  class="border-right border-bottom p-1"
                >
                  <div class="inner-col">
                    <div class="h4 mb-0">
                      เลข 5 ตัว
                    </div>
                    <small>80,000 บาท</small>
                    <div class="h2 mt-1">
                      {{ item.num5 }}
                    </div>

                  </div>
                </b-col>
                <b-col
                  lg="2"
                  md="4"
                  cols="6"
                  class="border-right border-bottom p-1"
                >
                  <div class="inner-col">
                    <div class="h4 mb-0">
                      เลข 4 ตัว
                    </div>
                    <small>24,000 บาท</small>
                    <div class="h2 mt-1">
                      {{ item.num4 }}
                    </div>

                  </div>
                </b-col>
                <b-col
                  lg="2"
                  md="4"
                  cols="6"
                  class="border-right border-bottom p-1"
                >
                  <div class="inner-col">
                    <div class="h4 mb-0">
                      เลขหน้า 3 ตัว
                    </div>
                    <small>1,600 บาท</small>
                    <div class="h2 mt-1">
                      {{ item.num3_front }}
                    </div>

                  </div>
                </b-col>
                <b-col
                  lg="2"
                  md="4"
                  cols="6"
                  class="border-right border-bottom p-1"
                >
                  <div class="inner-col">
                    <div class="h4 mb-0">
                      เลขท้าย 3 ตัว
                    </div>
                    <small>1,600 บาท</small>
                    <div class="h2 mt-1">
                      {{ item.num3t_back }}
                    </div>

                  </div>
                </b-col>
                <b-col
                  lg="2"
                  md="4"
                  cols="6"
                  class="border-right border-bottom p-1"
                >
                  <div class="inner-col">
                    <div class="h4 mb-0">
                      เลข 2 ตัว
                    </div>
                    <small>800 บาท</small>
                    <div class="h2 mt-1">
                      {{ item.num2 }}
                    </div>

                  </div>
                </b-col>
                <b-col
                  lg="2"
                  md="4"
                  cols="6"
                  class="border-right border-bottom p-1"
                >
                  <div class="inner-col">
                    <div class="h4 mb-0">
                      เลข 1 ตัว
                    </div>
                    <small>80 บาท</small>
                    <div class="h2 mt-1">
                      {{ item.num1 }}
                    </div>

                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card>

        <!-- <div>
          <div class="mt-2">
            <h3 class="text-white text-center">
              ผลการค้นหา
            </h3>
          </div>

          <b-row>
            <b-col
              v-for="item in lotteryset"
              :key="item._id"
              md="6"
              lg="4"
              cols="12"
              class="mt-2"
            >
              <div class="lotto-items">
                <div class="card7">
                  <div class="chip">
                    <span class="bankk">{{ item.lottonumber }}</span>
                  </div>
                  <div class="number">
                    {{ item.roundth }}
                  </div>
                  <div class="name">
                    <div class="price-lotto">
                      <h1>100 บาท</h1>
                    </div>
                  </div>
                  <div class="from">
                    <span class="txt-a">งวดที่</span> 1
                  </div>
                  <div class="to">
                    <span class="txt-a">ชุดที่</span> {{ item.series_no }}
                  </div>
                </div>
                <div class="mt-1">
                  <b-button
                    block
                    size="lg"
                    variant="primary"
                    class="btn-add"
                    @click="addcard(item)"
                  >
                    <i class="fas fa-shopping-basket" /> เพิ่มลงตะกร้า
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-pagination
            v-model="currentPage"
            class="mt-1"
            hide-goto-end-buttons
            :total-rows="totalRows"
            first-number
            last-number
            align="center"
            @input="ChangePage()"
          />
          <br>
          <br>
        </div> -->
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  // BButton,
  BCol,
  BRow,
  // BPagination,
  BOverlay,
  BIconController,
  BCard,
} from 'bootstrap-vue'
import BannerSlide from './Banner/BannerSlide.vue'
// import OtpInput from './components/OtpInput.vue'

export default {
  components: {
    BannerSlide,
    // BButton,
    BCol,
    BRow,
    // OtpInput,
    // BPagination,
    BOverlay,
    BIconController,
    BCard,
  },
  data() {
    return {
      renderComponent: true,
      input_numlot: '',
      slide: 0,
      sliding: null,
      lotteryset: [],
      round: null,
      totalRows: 1,
      currentPage: 1,
      show: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      ResultLast: null,
      Result: [],
    }
  },
  mounted() {
    this.GetResult()
    localStorage.removeItem('otp')
    localStorage.removeItem('type')
    if (this.UserData) {
      // this.getLotteryByagent()
      // this.getround()
      this.getBalance()
    } else {
      // this.getLottery()
      // this.getround()
    }
  },
  methods: {
    Search() {
      this.$router.push({ name: 'buy-lottery' })
    },
    GetResult() {
      console.log('GetResult')
      this.$http
        .get('https://api.sabaideelotto.com/api/seamless/lottery/GetResult')
        .then(ress => {
          this.ResultLast = ress.data.resultlast
          this.Result = ress.data.result
        })
    },
    ChangePage() {
      if (this.UserData.role) {
        this.getLotteryByagent()
      } else {
        this.getLottery()
      }
    },
    getLotteryByagent() {
      this.show = true
      const Obj = {
        page: this.currentPage,
        lottotype: JSON.parse(localStorage.getItem('type')),
        lottonumber: JSON.parse(localStorage.getItem('otp')),
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/lottery/home/getlottoByagent', Obj)
        .then(response => {
          this.lotteryset = response.data.lottery
          this.totalRows = response.data.total
          this.show = false
          // localStorage.removeItem('otp')
          // localStorage.removeItem('type')
          // console.log(this.lotteryset)
        })
        .catch(error => {
          this.show = false
          console.log(error)
        })
    },
    getBalance() {
      // console.log(this.UserData)
      const Obj = {
        UserToken: JSON.parse(localStorage.getItem('UserToken')),
      }
      this.$http
        .get('https://api.sabaideelotto.com/api/seamless/wallet/show', Obj)
        .then(ress => {
          console.log(ress.data)
          // this.balance = ress.data.balance
          // localStorage.setItem('balance', JSON.stringify(ress.data.balance))
        })
    },
    getround() {
      this.$http
        .get('https://api.sabaideelotto.com/api/seamless/lottery/getrounddetail')
        .then(ress => {
          // console.log(ress.data)
          this.round = ress.data
        })
    },
    detail(item) {
      this.$router.push({ name: 'lottery-set-agent', params: { id: item } })
    },
    getLottery() {
      this.show = true
      const Obj = {
        page: this.currentPage,
        lottotype: JSON.parse(localStorage.getItem('type')),
        lottonumber: JSON.parse(localStorage.getItem('otp')),
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/seamless/lottery/home/getlotto', Obj)
        .then(response => {
          this.lotteryset = response.data.lottery
          this.totalRows = response.data.total
          this.show = false
          // localStorage.removeItem('otp')
          // localStorage.removeItem('type')
          // console.log(this.lotteryset)
        })
        .catch(error => {
          this.show = false
          console.log(error)
        })
    },
    cleartext() {
      this.input_numlot = ''
      localStorage.removeItem('otp')
      localStorage.removeItem('type')
      this.renderComponent = false
      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true
      })
      this.getLottery()
    },
    addcard(lotto) {
      // console.log(localStorage.getItem('userData'))
      if (localStorage.getItem('userData')) {
        const Obj = {
          lottonumber: lotto.lottonumber,
        }
        this.$http
          .post('https://api.sabaideelotto.com/api/seamless/lottery/addcart', Obj)
          .then(() => {
            this.getLottery()
          })
          .catch(error => console.log(error))
      } else {
        this.SwalError('กรุณา เข้าสู่ระบบก่อนทำการซื้อ')
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.tv-live {
    border-radius: 10px;
    transform: scale(1);
    animation: pulse-live 2s infinite;
}

@keyframes pulse-live {
    0% {
        transform: scale(0.90);
        box-shadow: 0 0 0 0 rgba(197, 2, 2, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 87, 87, 0.103);
    }

    100% {
        transform: scale(0.90);
        box-shadow: 0 0 0 0 rgba(231, 69, 69, 0);
    }
}
</style>
